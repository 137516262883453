import 'mapbox-gl/dist/mapbox-gl.css';
import "react-datepicker/dist/react-datepicker.css";

import { Button, ButtonGroup, Offcanvas, Card, Col, Dropdown, Form, Row, Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState, useContext, useCallback } from 'react';

import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContext } from '../App';
import apiService from "../api";
import { faCopy, faPlus, faTrash, faSave, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz']
const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

const locale = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

const size = 100;

export const monthNameMap = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık"
];

export const hourDateOptions = [
  <option key="1">0</option>,
  <option key="2">1</option>,
  <option key="3">2</option>,
  <option key="4">3</option>,
  <option key="5">4</option>,
  <option key="6">5</option>,
  <option key="7">6</option>,
  <option key="8">7</option>,
  <option key="9">8</option>,
  <option key="10">9</option>,
  <option key="11">10</option>,
  <option key="12">11</option>,
  <option key="13">12</option>,
  <option key="14">13</option>,
  <option key="15">14</option>,
  <option key="16">15</option>,
  <option key="17">16</option>,
  <option key="18">17</option>,
  <option key="19">18</option>,
  <option key="20">19</option>,
  <option key="21">20</option>,
  <option key="22">21</option>,
  <option key="23">22</option>,
  <option key="24">23</option>
]

export const monthDateOptions = [
  <option key="1" value="0">{monthNameMap[0]}</option>,
  <option key="2" value="1">{monthNameMap[1]}</option>,
  <option key="3" value="2">{monthNameMap[2]}</option>,
  <option key="4" value="3">{monthNameMap[3]}</option>,
  <option key="5" value="4">{monthNameMap[4]}</option>,
  <option key="6" value="5">{monthNameMap[5]}</option>,
  <option key="7" value="6">{monthNameMap[6]}</option>,
  <option key="8" value="7">{monthNameMap[7]}</option>,
  <option key="9" value="8">{monthNameMap[8]}</option>,
  <option key="10" value="9">{monthNameMap[9]}</option>,
  <option key="11" value="10">{monthNameMap[10]}</option>,
  <option key="12" value="11">{monthNameMap[11]}</option>
]

export const yearDateOptions = [
  <option key="1">2023</option>,
  <option key="2">2024</option>,
  <option key="3">2025</option>,
  <option key="4">2026</option>,
  <option key="5">2027</option>,
]

const getDaysInMonth = (month) => {
  const year = new Date().getFullYear();
  return new Date(year, month + 1, 0).getDate();
};

const CustomerVehicleDetail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vehicleId = queryParams.get('vehicleId');
  const _id = queryParams.get('_id');
  
  const [customerSummary, setCustomerSummary] = useState(null);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);

  const [routes, setRoutes] = useState([]);

  const mapRef = useRef();
  const { accessToken, setSasToken, setProgress, darkMode } = useContext(MainContext);

  const fetchCustomerSummary = useCallback(async () => {
    try {
      const response = await apiService.getWithFirstResolve('customer/summary', accessToken, setProgress);
      const data = await response.json();
      if (data) {
        localStorage.setItem('customerSummary', JSON.stringify(data));
        return data;
      }
    } catch (error) {
      console.error('Error fetching customer summary:', error);
      toast.error('Müşteri verilerini getirirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    }
    return null;
  }, [accessToken, setProgress]);

  useEffect(() => {
    const initializeData = async () => {
      let parsedCustomerSummary = null;
      const storedCustomerSummary = localStorage.getItem('customerSummary');
      
      if (storedCustomerSummary) {
        parsedCustomerSummary = JSON.parse(storedCustomerSummary);
      } else {
        parsedCustomerSummary = await fetchCustomerSummary();
      }

      if (parsedCustomerSummary) {
        setCustomerSummary(parsedCustomerSummary);

        // Find the current vehicle and route
        let foundVehicle = null;
        let foundRoute = null;
        let projectRoutes = [];
        let projectCopy = {};
        for (const project of parsedCustomerSummary.projects) {
          const route = project.routes.find(r => r._id === _id && r.vehicleId === vehicleId);
          if (route) {
            foundRoute = route;
            foundVehicle = parsedCustomerSummary.vehicles[vehicleId];
            projectRoutes = project.routes;
            projectCopy = project;
            break;
          }
        }

        if (foundVehicle && foundRoute) {
          setRoutes(projectRoutes);
          setCurrentRoute(foundRoute);
          setCurrentProject(projectCopy);
          setCurrentVehicle(foundVehicle);

          // Fetch the vehicle's history when the component mounts
          fetchTravelHistory(foundVehicle.gpsUrl);
        } else {
          console.error('Vehicle or route not found');
        }
      }
    };

    initializeData();
  }, [vehicleId, _id, fetchCustomerSummary]);

  useEffect(() => {
    if (!accessToken)
      return;
      
    apiService.getWithFirstResolve('acquireSasTokenForDocs', accessToken, setProgress)
    .then(data => {
      setSasToken(data.headers.get('Sas-Token'));
      return data.json();
    })
    .then(data => (data));
  }, [])

  const [vehicleHistory, setVehicleHistory] = useState(null);
  const now = new Date();
  let yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(now.getDate() - 2);
  let fourDaysAgo = new Date();
  fourDaysAgo.setDate(now.getDate() - 4);

  let oneDayAfterFourDaysAgo = new Date();
  oneDayAfterFourDaysAgo.setDate(now.getDate() - 3);
  let oneDayAfterTwoDaysAgo = new Date();
  oneDayAfterTwoDaysAgo.setDate(now.getDate() - 1);

  const [startDate, setStartDate] = useState(yesterday);
  const [startTime, setStartTime] = useState(() => {
    const date = new Date();
    date.setUTCHours(5, 0, 0, 0); // 8 AM in Turkey is 5 AM UTC
    return date.toTimeString().slice(0, 5); // "08:00"
  });
  const [finishDate, setFinishDate] = useState(now);
  const [finishTime, setFinishTime] = useState(now.getHours()?.toString()?.padStart(2, '0') + ":" + now.getMinutes()?.toString()?.padStart(2, '0'));

  const [showSidebar, setShowSidebar] = useState(false);

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const [markers, setMarkers] = useState([]);

  const [lastClickedIcon, setLastClickedIcon] = useState(null);

  const [isEditingStops, setIsEditingStops] = useState(false);
  const [stopList, setStopList] = useState([]);
  const [showStopModal, setShowStopModal] = useState(false);
  const [currentStop, setCurrentStop] = useState(null);

  const handleHistoryFilter = () => {
    if (currentVehicle?.gpsUrl != undefined)
    {
      const threeYearsAgo = new Date();
      threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

      if (startDate === null || startDate === undefined) {
        alert("Başlangıç tarihi seçilmedi.");
        return;
      }

      if (finishDate === null || finishDate === undefined) {
        alert("Bitiş tarihi seçilmedi.");
        return;
      }
      if (startTime === null || startTime === undefined || startTime === "") {
        alert("Başlangıç saati seçilmedi.");
        return;
      }

      if (finishTime === null || finishTime === undefined || finishTime === "") {
        alert("Bitiş saati seçilmedi.");
        return;
      }


      // Check if startDate is earlier than 3 years ago
      if (new Date(startDate) < threeYearsAgo) {
        alert("Başlangıç tarihi 3 yıldan erken olamaz.");
        return;
      }

      const fromDate = new Date(startDate);
      const [hours, minutes] = startTime.split(':').map(Number);
      fromDate.setHours(hours, minutes, 0, 0);
      const fromDateISOString = fromDate.toISOString();

      const untilDate = new Date(finishDate);
      const [hours2, minutes2] = finishTime.split(':').map(Number);
      untilDate.setHours(hours2, minutes2, 0, 0);
      const untilDateISOString = untilDate.toISOString();

      apiService.getWithFirstResolve(`vehicle/${currentVehicle?.gpsUrl}/travelHistory?from=${fromDateISOString}&until=${untilDateISOString}`, accessToken, setProgress)
        .then(data => data.json())
        .then(data => {
          if (data.message) {
            toast.info(data.message);
          } else {
            setVehicleHistory(data);
            showSidebar && handleCloseSidebar();
            // Trigger the update of the map after setting the new vehicle history
            const newCoordinates = data.map(entry => [entry?.location?.lon, entry?.location?.lat]);
            updateRouteAndDot(newCoordinates);
          }
        })
        .catch(err => toast.error(err.message));
    }
  }

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
  
    onAdd: function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },
  
    render: function () {
      const duration = 1000;
      const t = (performance.now() % duration) / duration;
  
      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.7 * t + radius;
      const context = this.context;
  
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
      );
      context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      context.fill();
  
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();
  
      this.data = context.getImageData(0, 0, this.width, this.height).data;
  
      mapRef.current.triggerRepaint();
  
      return true;
    }
  };

  const handleDocForm = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Check if currentVehicle.plate exists and set the document title accordingly
    if (currentVehicle && currentVehicle["plate"]) {
      document.title = `${currentVehicle["plate"]}`;
    }
  }, [currentVehicle]);

  useEffect(() => {
    if (currentVehicle?.lastLocation != undefined)
    {
      const fromDate = new Date(startDate);
      const [hours, minutes] = startTime.split(':').map(Number);
      fromDate.setHours(hours, minutes, 0, 0);
      const fromDateISOString = fromDate.toISOString();

      const untilDate = new Date(finishDate);
      const [hours2, minutes2] = finishTime.split(':').map(Number);
      untilDate.setHours(hours2, minutes2, 0, 0);
      const untilDateISOString = untilDate.toISOString();

      apiService.getWithFirstResolve(`vehicle/${currentVehicle?.gpsUrl}/travelHistory?from=${fromDateISOString}&until=${untilDateISOString}`, accessToken, setProgress)
        .then(data => data.json())
        .then(data => setVehicleHistory(data));
    }
  }, []);

  const initializeMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiZW1za2FwbGFubiIsImEiOiJjbHplZHZ0dnkwcHN0MmpwbjcwdHhwNzQyIn0.s--gDj5BIX6b0ll_AB3TIg';
    mapRef.current = new mapboxgl.Map({
      container: 'mapbox-parent',
      style: darkMode ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11',
      center: [29.032669, 41.032921],
      zoom: 10
    });

    mapRef.current.on('load', () => {
      console.log('Map loaded');
      mapRef.current.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      const addSourceIfNotExists = (id, source) => {
        if (!mapRef.current.getSource(id)) {
          mapRef.current.addSource(id, source);
        }
      };

      const addLayerIfNotExists = (id, layer) => {
        if (!mapRef.current.getLayer(id)) {
          mapRef.current.addLayer(layer);
        }
      };

      // Add sources and layers for route features
      addSourceIfNotExists('route-segments', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      addLayerIfNotExists('route-segments', {
        'id': 'route-segments',
        'type': 'line',
        'source': 'route-segments',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#3151f5',
          'line-width': 4
        }
      });

      addSourceIfNotExists('route-stops', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      addLayerIfNotExists('route-stops', {
        'id': 'route-stops',
        'type': 'circle',
        'source': 'route-stops',
        'paint': {
          'circle-radius': 5,
          'circle-color': '#f44336',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });

      addSourceIfNotExists('route-starts', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      addLayerIfNotExists('route-starts', {
        'id': 'route-starts',
        'type': 'circle',
        'source': 'route-starts',
        'paint': {
          'circle-radius': 5,
          'circle-color': '#4CAF50',
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });

      addSourceIfNotExists('route-end-point', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      addLayerIfNotExists('route-end-point', {
        'id': 'route-end-point',
        'type': 'symbol',
        'source': 'route-end-point',
        'layout': {
          'icon-image': 'end-icon',
          'icon-size': 0.5,
          'icon-allow-overlap': true
        }
      });

      // Add source and layer for editable stops
      addSourceIfNotExists('editable-stops', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      });

      addLayerIfNotExists('editable-stops', {
        'id': 'editable-stops',
        'type': 'symbol',
        'source': 'editable-stops',
        'layout': {
          'icon-image': 'marker-15',
          'text-field': ['get', 'order'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top'
        }
      });

      if (vehicleHistory?.length > 0) {
        addRouteAndDot();
      }

      addLegend();
    });
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(darkMode ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11');
    }
  }, [darkMode]);

  const addLegend = () => {
    const legend = document.createElement('div');
    legend.className = 'map-legend';
    legend.innerHTML = `
      <div style="
        padding: 10px;
        background-color: ${darkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
        color: ${darkMode ? '#ffffff' : '#000000'};
        border-radius: 5px;
        position: absolute;
        bottom: 8vh;
        left: 10px;
        z-index: 1;
        font-size: 12px;
        line-height: 20px;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 5px 10px;
      ">
        <div style="display: flex; align-items: center;"><span style="background-color: #4CAF50; width: 8px; height: 8px; display: inline-block; border-radius: 50%;"></span></div>
        <div>Başlangıç</div>
        <div style="display: flex; align-items: center;"><span style="background-color: #f44336; width: 8px; height: 8px; display: inline-block; border-radius: 50%;"></span></div>
        <div>Duruş</div>
        <div style="display: flex; align-items: center;"><span style="background-color: #3151f5; width: 20px; height: 4px; display: inline-block;"></span></div>
        <div>Rota</div>
        <div style="display: flex; align-items: center;">
          <svg width="16" height="16" viewBox="0 0 40 40" style="vertical-align: middle;">
            <circle cx="20" cy="20" r="18" fill="#ff0000" stroke="#ffffff" stroke-width="2"/>
            <text x="20" y="28" font-family="Arial" font-size="24" fill="#ffffff" text-anchor="middle">X</text>
          </svg>
        </div>
        <div>Bitiş</div>
      </div>
    `;
    mapRef.current.getContainer().appendChild(legend);
  };

  const addRouteAndDot = () => {
    if (!mapRef.current.isStyleLoaded()) {
      console.log('Style not loaded, retrying...');
      setTimeout(addRouteAndDot, 100);
      return;
    }

    const coordinates = vehicleHistory.map(entry => [entry?.location?.lon, entry?.location?.lat]);

    if (mapRef.current.getSource('route')) {
      updateRouteAndDot(coordinates);
    } else {
      try {
        // Add a source for route segments
        mapRef.current.addSource('route-segments', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        mapRef.current.addLayer({
          'id': 'route-segments',
          'type': 'line',
          'source': 'route-segments',
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-color': '#3151f5',
            'line-width': 4
          }
        });

        // Add a layer for stop indicators
        mapRef.current.addSource('stops', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        mapRef.current.addLayer({
          'id': 'stops',
          'type': 'circle',
          'source': 'stops',
          'paint': {
            'circle-radius': 5,
            'circle-color': '#f44336',
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff'
          }
        });

        // Add a layer for start indicators
        mapRef.current.addSource('starts', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        mapRef.current.addLayer({
          'id': 'starts',
          'type': 'circle',
          'source': 'starts',
          'paint': {
            'circle-radius': 5,
            'circle-color': '#4CAF50', // Green color
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff'
          }
        });

        // Add a layer for the end point
        mapRef.current.addSource('end-point', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': []
          }
        });

        mapRef.current.addLayer({
          'id': 'end-point',
          'type': 'symbol',
          'source': 'end-point',
          'layout': {
            'icon-image': 'end-icon',
            'icon-size': 0.5,
            'icon-allow-overlap': true
          }
        });

        // Create a custom icon for the end point
        const endIcon = new Image(40, 40);
        endIcon.onload = () => {
          mapRef.current.addImage('end-icon', endIcon);
        };
        endIcon.src = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <circle cx="20" cy="20" r="18" fill="#ff0000" stroke="#ffffff" stroke-width="2"/>
            <text x="20" y="28" font-family="Arial" font-size="24" fill="#ffffff" text-anchor="middle">X</text>
          </svg>
        `);

        // Add click event for stop indicators
        mapRef.current.on('click', 'stops', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = `<h3>Stop Point</h3><p>Coordinates: ${coordinates[0].toFixed(6)}, ${coordinates[1].toFixed(6)}</p>`;

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(mapRef.current);
        });

        // Add click event for start indicators
        mapRef.current.on('click', 'starts', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = `<h3>Start Point</h3><p>Coordinates: ${coordinates[0].toFixed(6)}, ${coordinates[1].toFixed(6)}</p>`;

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(mapRef.current);
        });

        // Change the cursor to a pointer when hovering over stop or start points
        mapRef.current.on('mouseenter', ['stops', 'starts'], () => {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves
        mapRef.current.on('mouseleave', ['stops', 'starts'], () => {
          mapRef.current.getCanvas().style.cursor = '';
        });

        updateRouteAndDot(coordinates);

        mapRef.current.fitBounds([
          [Math.min(...coordinates.map(c => c[0])), Math.min(...coordinates.map(c => c[1]))],
          [Math.max(...coordinates.map(c => c[0])), Math.max(...coordinates.map(c => c[1]))]
        ], { padding: 50 });

        console.log('Route, markers, and legend added successfully');
      } catch (error) {
        console.error('Error adding route segments, markers, and legend:', error);
      }
    }
  };

  const updateRouteAndDot = (coordinates) => {
    try {
      // Create route segments
      const routeSegments = [];
      let currentSegment = [];
      let isMoving = false;

      vehicleHistory.forEach((entry, index) => {
        if (entry.speed > 0 && !isMoving) {
          // Start of a new segment
          currentSegment = [[entry.location.lon, entry.location.lat]];
          isMoving = true;
        } else if (entry.speed === 0 && isMoving) {
          // End of a segment
          currentSegment.push([entry.location.lon, entry.location.lat]);
          routeSegments.push({
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'coordinates': currentSegment
            }
          });
          isMoving = false;
        } else if (isMoving) {
          // Continue the current segment
          currentSegment.push([entry.location.lon, entry.location.lat]);
        }
      });

      // Add the last segment if it's still moving
      if (isMoving && currentSegment.length > 0) {
        routeSegments.push({
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': currentSegment
          }
        });
      }

      // Update the route segments
      mapRef.current.getSource('route-segments').setData({
        'type': 'FeatureCollection',
        'features': routeSegments
      });

      // Update route stop indicators
      const stopFeatures = vehicleHistory.filter((entry, index) => 
        index > 0 && entry.speed === 0 && vehicleHistory[index - 1].speed > 0
      ).map(entry => ({
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [entry.location.lon, entry.location.lat]
        },
        'properties': {
          'description': `<h3>Stop Point</h3><p>Coordinates: ${entry.location.lon.toFixed(6)}, ${entry.location.lat.toFixed(6)}</p>`
        }
      }));

      mapRef.current.getSource('route-stops').setData({
        'type': 'FeatureCollection',
        'features': stopFeatures
      });

      // Update route start indicators
      const startFeatures = vehicleHistory.filter((entry, index) => 
        index === 0 || (entry.speed > 0 && vehicleHistory[index - 1].speed === 0)
      ).map(entry => ({
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [entry.location.lon, entry.location.lat]
        },
        'properties': {
          'description': `<h3>Start Point</h3><p>Coordinates: ${entry.location.lon.toFixed(6)}, ${entry.location.lat.toFixed(6)}</p>`
        }
      }));

      mapRef.current.getSource('route-starts').setData({
        'type': 'FeatureCollection',
        'features': startFeatures
      });

      // Update route end point
      const lastCoordinate = coordinates[coordinates.length - 1];
      if (lastCoordinate) {
        mapRef.current.getSource('route-end-point').setData({
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': lastCoordinate
            },
            'properties': {
              'description': `<h3>End Point</h3><p>Coordinates: ${lastCoordinate[0].toFixed(6)}, ${lastCoordinate[1].toFixed(6)}</p>`
            }
          }]
        });
      }

      zoomToEntireRoute();
    } catch (error) {
      console.error('Error updating route segments, markers, and end point:', error);
    }
  };

  const createCustomMarker = (iconUrl) => {
    const el = document.createElement('div');
    el.className = 'custom-marker';
    el.style.backgroundImage = `url(${iconUrl})`;
    el.style.width = '32px';
    el.style.height = '32px';
    el.style.backgroundSize = '100%';
    return el;
  };

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    if (mapRef.current && vehicleHistory?.length > 0) {
      addRouteAndDot();
    }
  }, [vehicleHistory]);

  // Replace the zoomIntoPulsingDot function with this:
  const zoomToEntireRoute = () => {
    if (mapRef.current && vehicleHistory?.length > 0) {
      const coordinates = vehicleHistory.map(entry => [entry.location.lon, entry.location.lat]);
      
      // Create a 'LngLatBounds' with the first coordinate
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      // Extend the 'LngLatBounds' to include every coordinate
      for (const coord of coordinates) {
        bounds.extend(coord);
      }

      mapRef.current.fitBounds(bounds, {
        padding: 50, // Add some padding around the bounds
        maxZoom: 15, // Limit the max zoom level
        duration: 1000 // Animation duration in milliseconds
      });
    }
  }

  const handleRouteChange = (selectedRouteId) => {
    const selectedRoute = routes.find(route => route._id === selectedRouteId);
    if (selectedRoute) {
      setCurrentRoute(selectedRoute);
      setCurrentProject(customerSummary.projects.find(p => p.routes.find(r => r._id === selectedRouteId)));
      // Update current vehicle
      if (customerSummary) {
        const newVehicle = customerSummary.vehicles[selectedRoute.vehicleId];
        setCurrentVehicle(newVehicle);

        // Fetch travel history for the new vehicle
        if (newVehicle && newVehicle.gpsUrl) {
          fetchTravelHistory(newVehicle.gpsUrl);
        }

        // Fetch stop list for the new route
        const projectId = customerSummary.projects.find(p => p.routes.find(r => r._id === selectedRouteId))?.id;
        if (projectId) {
          fetchStopList(projectId, selectedRouteId);
        }
      }

      if (showSidebar) {
        handleCloseSidebar();
      }
    }
  };

  const fetchTravelHistory = useCallback((gpsUrl) => {
    if (gpsUrl) {
      const fromDate = new Date(startDate);
      const [hours, minutes] = startTime.split(':').map(Number);
      fromDate.setHours(hours, minutes, 0, 0);
      const fromDateISOString = fromDate.toISOString();

      const untilDate = new Date(finishDate);
      const [hours2, minutes2] = finishTime.split(':').map(Number);
      untilDate.setHours(hours2, minutes2, 0, 0);
      const untilDateISOString = untilDate.toISOString();

      apiService.getWithFirstResolve(`vehicle/${gpsUrl}/travelHistory?from=${fromDateISOString}&until=${untilDateISOString}`, accessToken, setProgress)
        .then(data => data.json())
        .then(data => {
          if (data.message) {
            toast.info(data.message);
          } else {
            setVehicleHistory(data);
            // Trigger the update of the map after setting the new vehicle history
            const newCoordinates = data.map(entry => [entry?.location?.lon, entry?.location?.lat]);
            updateRouteAndDot(newCoordinates);
          }
        })
        .catch(err => toast.error(err.message));
    }
  }, [accessToken, setProgress, startDate, startTime, finishDate, finishTime]);

  useEffect(() => {
    if (vehicleHistory) {
      const newCoordinates = vehicleHistory.map(entry => [entry?.location?.lon, entry?.location?.lat]);
      updateRouteAndDot(newCoordinates);
      zoomToEntireRoute();
    }
  }, [vehicleHistory]);

  useEffect(() => {
    if (currentVehicle?.gpsUrl) {
      fetchTravelHistory(currentVehicle.gpsUrl);
    }
  }, [currentVehicle, fetchTravelHistory]);

  const handleEditStops = () => {
    setIsEditingStops(!isEditingStops);
    if (!isEditingStops) {
      // Entering edit mode
      if (currentRoute && currentRoute.stopList) {
        setStopList(currentRoute.stopList);
        currentRoute.stopList.forEach(stop => addMarkerToMap(stop));
      }
      mapRef.current.on('click', handleAddStop);
      
      // Add edit mode indicator
      const editModeIndicator = document.createElement('div');
      editModeIndicator.id = 'edit-mode-indicator';
      editModeIndicator.key = 'edit-mode-indicator';
      editModeIndicator.innerHTML = 'Durak Düzenleme Modu';
      editModeIndicator.style.position = 'absolute';
      editModeIndicator.style.top = '50px';
      editModeIndicator.style.left = '50%';
      editModeIndicator.style.transform = 'translateX(-50%)';
      editModeIndicator.style.backgroundColor = 'rgba(255, 0, 0, 0.7)';
      editModeIndicator.style.color = 'white';
      editModeIndicator.style.padding = '5px 10px';
      editModeIndicator.style.borderRadius = '5px';
      editModeIndicator.style.zIndex = '1000';
      mapRef.current.getContainer().appendChild(editModeIndicator);
    } else {
      // Exiting edit mode
      mapRef.current.off('click', handleAddStop);
      markers.forEach(({ marker }) => marker.remove());
      setMarkers([]);
      
      // Remove edit mode indicator
      const editModeIndicator = document.getElementById('edit-mode-indicator');
      if (editModeIndicator) {
        editModeIndicator.remove();
      }
    }
  };

  const handleAddStop = (e) => {
    const { lng, lat } = e.lngLat;
    const newStop = {
      name: `Stop ${stopList.length + 1}`,
      location: { lat, lng },
      order: stopList.length + 1,
      note: '',
      isPassive: false
    };
    const updatedStopList = [...stopList, newStop];
    setStopList(updatedStopList);
    addMarkerToMap(newStop);
  };

  const addStopsToMap = (stops) => {
    if (!mapRef.current) {
      console.error('Map reference is not available');
      return;
    }

    console.log('Adding stops to map:', stops); // Debug log

    // Remove existing stop markers
    markers.forEach(({ marker }) => marker.remove());
    setMarkers([]);

    const features = stops.map(stop => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [stop.location.lng, stop.location.lat]
      },
      properties: {
        order: stop.order.toString(),
        name: stop.name,
        note: stop.note,
        isPassive: stop.isPassive,
        _id: stop._id
      }
    }));

    mapRef.current.getSource('editable-stops')?.setData({
      type: 'FeatureCollection',
      features: features
    });

    // Add markers for each stop
    stops.forEach(stop => addMarkerToMap(stop));
  };

  const addMarkerToMap = (stop) => {
    console.log('Adding marker for stop:', stop); // Debug log

    // Create an SVG element
    const svgMarker = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgMarker.setAttribute('width', '30');
    svgMarker.setAttribute('height', '30');
    svgMarker.setAttribute('viewBox', '0 0 30 30');

    // Create the marker circle
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circle.setAttribute('cx', '15');
    circle.setAttribute('cy', '15');
    circle.setAttribute('r', '12');
    circle.setAttribute('fill', stop.isPassive ? '#808080' : '#3FB1CE');
    circle.setAttribute('stroke', 'white');
    circle.setAttribute('stroke-width', '2');

    // Create the text element for the order number
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.setAttribute('x', '15');
    text.setAttribute('y', '20');
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('fill', 'white');
    text.setAttribute('font-size', '12px');
    text.textContent = stop.order;

    // Append circle and text to the SVG
    svgMarker.appendChild(circle);
    svgMarker.appendChild(text);

    // Create a wrapper div for the SVG
    const el = document.createElement('div');
    el.appendChild(svgMarker);

    const marker = new mapboxgl.Marker({
      element: el,
      draggable: true
    })
      .setLngLat([stop.location.lng, stop.location.lat])
      .addTo(mapRef.current);

    console.log('Marker added at:', [stop.location.lng, stop.location.lat]); // Debug log

    marker.on('dragend', () => updateStopPosition(stop, marker));
    el.addEventListener('click', () => openStopModal(stop));

    // Store the marker reference
    setMarkers(prevMarkers => [...prevMarkers, { id: stop._id, marker }]);
  };

  const updateStopPosition = (stop, marker) => {
    const newPosition = marker.getLngLat();
    const updatedStopList = stopList.map(s => 
      s._id === stop._id ? { ...s, location: { lat: newPosition.lat, lng: newPosition.lng } } : s
    );
    setStopList(updatedStopList);
  };

  const openStopModal = (stop) => {
    setCurrentStop(stop);
    setShowStopModal(true);
  };

  const handleSaveStop = (updatedStop) => {
    const updatedStopList = stopList.map(s => 
      s._id === updatedStop._id ? updatedStop : s
    );
    setStopList(updatedStopList);
    addStopsToMap(updatedStopList);
    setShowStopModal(false);
  };

  const handleDeleteStop = (stopToDelete) => {
    const updatedStopList = stopList.filter(s => s._id !== stopToDelete._id)
      .map((s, index) => ({ ...s, order: index + 1 }));
    apiService.update(
      updatedStopList,
      `projects/${currentProject.id}/vehicles/${currentRoute._id}/stopList`,
      accessToken,
      setProgress
    )
    .then(response => {
      if (response.error) {
        alert(response.error);
      } else {
        setStopList(updatedStopList);
        addStopsToMap(updatedStopList);
        setShowStopModal(false);
      }
    })
    .catch(error => {
      console.error('Error deleting stop:', error);
      alert('An error occurred while deleting the stop');
    });
  };

  const handleSaveStopList = async () => {
    try {
      const response = await apiService.update(
        stopList,
        `projects/${currentProject.id}/vehicles/${currentRoute._id}/stopList`,
        accessToken,
        setProgress
      );
      if (!response.error) {
        toast.success('Duraklar başarıyla kaydedildi.');
        setIsEditingStops(false);
        setCurrentRoute({ ...currentRoute, stopList });
        mapRef.current.off('click', handleAddStop);
        markers.forEach(({ marker }) => marker.remove());
        setMarkers([]);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.error('Error saving stop list:', error);
      toast.error('Durakları kaydederken bir hata oluştu.');
    }
  };

  const fetchStopList = useCallback(async (projectId, routeId) => {
    if (!projectId || !routeId) return;

    try {
      const response = await apiService.get(`projects/${projectId}/vehicles/${routeId}/stopList`, accessToken, setProgress);
      console.log('Response:', response);
      if (response.error) {
        toast.error(response.error);
      } else {
        setStopList(response);
        addStopsToMap(response);
      }
    } catch (error) {
      console.error('Error fetching stop list:', error);
      toast.error('Durakları getirirken bir hata oluştu');
    }
  }, [accessToken, setProgress]);

  useEffect(() => {
    if (currentRoute && customerSummary) {
      const projectId = customerSummary.projects.find(p => p.routes.find(r => r._id === currentRoute._id))?.id;
      if (projectId) {
        console.log('Fetching stop list for project:', projectId, 'and route:', currentRoute._id); // Debug log
        fetchStopList(projectId, currentRoute._id);
      }
    }
  }, [currentRoute, customerSummary, fetchStopList]);

  return (
    <div style={{height: 'calc(100vh - 56px)', backgroundColor: darkMode ? '#333' : '#fff', color: darkMode ? '#fff' : '#000'}}>
      <Offcanvas show={showSidebar} onHide={handleCloseSidebar} 
                 style={{backgroundColor: darkMode ? '#222' : '#fff', color: darkMode ? '#fff' : '#000'}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ayarlar</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col>
              <div><span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Plaka: </span></div>
              <div><span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Sürücü:</span></div>
              <div><span style={{ fontSize: '16px', fontWeight: 500, marginRight: 5 }}>Tel no: </span></div>
            </Col>
            <Col>
              {currentVehicle?.plate ? (
                <Button variant="link" style={{ padding: 0, fontSize: '16px' }} onClick={() => {
                  let doc = window.open(`http://${window.location.host}/vehicleDetailPart?details=${JSON.stringify(currentVehicle)}`, `${currentVehicle.plate}`, 'width=800,height=600');
                }}>{currentVehicle.plate}</Button>
              ) : (
                <span style={{ fontSize: '16px', color: '#6c757d' }}>---</span>
              )}<br />
              
              {currentVehicle?.driverName ? (
                <Button variant="link" style={{ padding: 0, fontSize: '16px', whiteSpace: 'nowrap' }} onClick={() => {
                  let doc = window.open(`http://${window.location.host}/driverDetail?details=${JSON.stringify(currentRoute)}`, `${currentVehicle?.plate}`, 'width=800,height=500');
                }}>{currentVehicle.driverName}</Button>
              ) : (
                <span style={{ fontSize: '16px', color: '#6c757d' }}>---</span>
              )}<br />
              
              {currentVehicle?.driverPhone ? (
                <>
                  <a href={`tel:+90${currentVehicle.driverPhone}`} style={{ fontSize: '16px' }}>{currentVehicle.driverPhone}</a>
                  <FontAwesomeIcon
                    className={`copy-icon ${lastClickedIcon === 'driverPhone' ? 'animate-copy' : ''}`}
                    icon={faCopy}
                    color={darkMode ? 'white' : 'black'}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(`+90${currentVehicle.driverPhone}`);
                      setLastClickedIcon('driverPhone');
                      setTimeout(() => setLastClickedIcon(null), 500); // Reset after animation
                    }}
                  />
                </>
              ) : (
                <span style={{ fontSize: '16px', color: '#6c757d' }}>---</span>
              )}
            </Col>
          </Row>
          
          {/* Add Route Ticker to mobile view */}
          <Row style={{ marginTop: '20px' }}>
            <h6>Güzergah</h6>
            <Col>
              <Form.Select 
                value={currentRoute ? currentRoute._id : ''}
                onChange={(e) => handleRouteChange(e.target.value)}
              >
                {routes && routes.map((route) => {
                  const vehiclePlate = customerSummary?.vehicles[route.vehicleId]?.plate || 'Plaka Yok';
                  return (
                    <option key={route._id} value={route._id}>
                      {`${route.name} - ${vehiclePlate}`}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>Saat & tarih:</span>
            <Col>
              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={startTime} onChange={(time) => setStartTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={startDate} onChange={(date) => setStartDate(date)} locale={locale} />
                </div>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 10, display: 'inline-block', width: 'auto' }} className="form-control" value={finishTime} onChange={(time) => setFinishTime(time.target.value)} type="time" />
                  <DatePicker className="form-control me-2" selected={finishDate} onChange={(date) => setFinishDate(date)} locale={locale} />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col xs={2} style={{ marginTop: 10, width: '100%' }} className="d-flex justify-content-end">
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} variant="secondary" disabled={currentVehicle?.lastLocation === undefined} onClick={e => { setStartDate(fourDaysAgo); setFinishDate(oneDayAfterFourDaysAgo); }}>
                  4 gün önce
                </Button>
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} variant="secondary" disabled={currentVehicle?.lastLocation === undefined} onClick={e => { setStartDate(twoDaysAgo); setFinishDate(oneDayAfterTwoDaysAgo); }}>
                  2 gün önce
                </Button>
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} disabled={currentVehicle?.lastLocation === undefined} onClick={e => {handleCloseSidebar();handleHistoryFilter()}}>
                  Sorgula
                </Button>
              </Col>
              <Col style={{ marginTop: 5, width: '100%' }} className="d-flex justify-content-end">
                <Button 
                  size="sm"
                  style={{ fontSize: '12px', whiteSpace: 'nowrap', marginRight: '5px' }} 
                  disabled={currentVehicle?.lastLocation === undefined} 
                  onClick={e => zoomToEntireRoute()}
                >
                  Rotayı Göster
                </Button>
                <Button 
                  size="sm"
                  style={{ fontSize: '12px', whiteSpace: 'nowrap' }} 
                  onClick={handleEditStops}
                  disabled={true}
                >
                  Durakları Düzenle
                </Button>
              </Col>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <div style={{ height: '100%', position: 'relative' }}>
        <div id="mapbox-parent" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          {currentVehicle?.gpsUrl === undefined && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
              color: darkMode ? '#fff' : '#000',
              padding: '20px',
              borderRadius: '10px',
              zIndex: 1,
              textAlign: 'center',
              fontSize: '22px',
              fontWeight: 'bold'
            }}>
              Araca ait GPS kaydı bulunamadı, yetkili birimlerle iletişime geçiniz.
            </div>
          )}
          <div style={{zIndex: 4, position: 'absolute', top: 10, left: 10}}>
            <Button variant={darkMode ? "light" : "primary"} id="open-sidebar-button" onClick={handleShowSidebar}>
              Ayarlar
            </Button>
          </div>
          
          {/* Mapbox content */}
          <div style={{position: 'absolute', top: 10, right: 10, zIndex: 1000}}>
            <Button 
              key="edit-stops-button"
              variant={isEditingStops ? "danger" : "primary"}
              onClick={handleEditStops}
              style={{ marginRight: '10px' }}
              disabled={true}
            >
              <FontAwesomeIcon icon={isEditingStops ? faTimes : faEdit} />
              {' '}
              {isEditingStops ? 'Düzenlemeyi Bitir' : 'Durakları Düzenle'}
            </Button>
            {isEditingStops && (
              <Button key="save-stops-button" variant="success" onClick={handleSaveStopList}>
                <FontAwesomeIcon icon={faSave} /> Durakları Kaydet
              </Button>
            )}
          </div>

          {/* Add the ToastContainer inside the map container */}
          <div style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1000,
            maxWidth: '300px', // Limit the width of toast messages
          }}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme={darkMode ? "dark" : "light"}
              style={{ fontSize: '14px' }} // Reduce font size
            />
          </div>
        </div>
        <div id="left-pane" style={{ 
          position: 'absolute', 
          top: '10px', 
          left: '10px', 
          backgroundColor: darkMode ? 'rgba(34, 34, 34, 0.9)' : 'rgba(240, 240, 240, 0.9)', 
          padding: '15px', 
          borderRadius: '10px',
          maxHeight: 'calc(100% - 20px)',
          overflowY: 'auto',
          color: darkMode ? '#fff' : '#000',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 5,
          fontSize: '14px' // Set a base font size for the entire left pane
        }}>
          <Row>
            <h5 style={{ fontSize: '16px', marginBottom: '10px' }}>Araç ve Sürücü Bilgileri</h5>
            <Row>
              <Col>
                <div><span style={{ fontWeight: 500, marginRight: 5 }}>Plaka: </span></div>
                <div><span style={{ fontWeight: 500, marginRight: 5 }}>Sürücü:</span></div>
                <div><span style={{ fontWeight: 500, marginRight: 5 }}>Tel no: </span></div>
              </Col>
              <Col>
                {currentVehicle?.plate ? (
                  <Button variant="link" style={{ padding: 0, fontSize: '14px' }} onClick={() => {
                    let doc = window.open(`http://${window.location.host}/vehicleDetailPart?details=${JSON.stringify(currentVehicle)}`, `${currentVehicle.plate}`, 'width=800,height=600');
                  }}>{currentVehicle.plate}</Button>
                ) : (
                  <span style={{ color: '#6c757d' }}>---</span>
                )}<br />
                
                {currentVehicle?.driverName ? (
                  <Button variant="link" style={{ padding: 0, fontSize: '14px', whiteSpace: 'nowrap' }} onClick={() => {
                    let doc = window.open(`http://${window.location.host}/driverDetail?details=${JSON.stringify(currentVehicle)}`, `${currentVehicle?.plate}`, 'width=800,height=500');
                  }}>{currentVehicle.driverName}</Button>
                ) : (
                  <span style={{ color: '#6c757d' }}>---</span>
                )}<br />
                
                {currentVehicle?.driverPhone ? (
                  <>
                    <a href={`tel:+90${currentVehicle.driverPhone}`} style={{ fontSize: '14px' }}>{currentVehicle.driverPhone}</a>
                    <FontAwesomeIcon
                      className={`copy-icon ${lastClickedIcon === 'driverPhone' ? 'animate-copy' : ''}`}
                      icon={faCopy}
                      color={darkMode ? 'white' : 'black'}
                      style={{ marginLeft: '5px', cursor: 'pointer', fontSize: '12px' }}
                      onClick={() => {
                        navigator.clipboard.writeText(`+90${currentVehicle.driverPhone}`);
                        setLastClickedIcon('driverPhone');
                        setTimeout(() => setLastClickedIcon(null), 500);
                      }}
                    />
                  </>
                ) : (
                  <span style={{ color: '#6c757d' }}>---</span>
                )}
              </Col>
            </Row>
          </Row>
          
          {/* Add Route Ticker */}
          <Row style={{ marginTop: '15px' }}>
            <span style={{ fontWeight: 500, marginBottom: '5px' }}>Güzergah:</span>
            <Col>
              <Form.Select 
                size="sm"
                value={currentRoute ? currentRoute._id : ''}
                onChange={(e) => handleRouteChange(e.target.value)}
              >
                {routes && routes.map((route) => {
                  const vehiclePlate = customerSummary?.vehicles[route.vehicleId]?.plate || 'Plaka Yok';
                  return (
                    <option key={route._id} value={route._id}>
                      {`${route.name} - ${vehiclePlate}`}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <span style={{ fontWeight: 500, marginBottom: '5px' }}>Saat & tarih:</span>
            <Col>
              <Row>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 5, display: 'inline-block', width: 'auto' }} className="form-control form-control-sm" value={startTime} onChange={(time) => setStartTime(time.target.value)} type="time" />
                  <DatePicker className="form-control form-control-sm me-2" selected={startDate} onChange={(date) => setStartDate(date)} locale={locale} />
                </div>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <div className="d-flex justify-content-end align-items-center">
                  <input style={{ marginRight: 5, display: 'inline-block', width: 'auto' }} className="form-control form-control-sm" value={finishTime} onChange={(time) => setFinishTime(time.target.value)} type="time" />
                  <DatePicker className="form-control form-control-sm me-2" selected={finishDate} onChange={(date) => setFinishDate(date)} locale={locale} />
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Col xs={2} style={{ marginTop: 10, width: '100%' }} className="d-flex justify-content-end">
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} variant="secondary" disabled={currentVehicle?.gpsUrl === undefined} onClick={e => { setStartDate(fourDaysAgo); setFinishDate(oneDayAfterFourDaysAgo); }}>
                  4 gün önce
                </Button>
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} variant="secondary" disabled={currentVehicle?.gpsUrl === undefined} onClick={e => { setStartDate(twoDaysAgo); setFinishDate(oneDayAfterTwoDaysAgo); }}>
                  2 gün önce
                </Button>
                <Button size="sm" style={{ marginLeft: 5, fontSize: '12px', whiteSpace: 'nowrap' }} disabled={currentVehicle?.gpsUrl === undefined} onClick={e => handleHistoryFilter()}>
                  Sorgula
                </Button>
              </Col>
              <Col style={{ marginTop: 5, width: '100%' }} className="d-flex justify-content-end">
                <Button 
                  size="sm"
                  style={{ fontSize: '12px', whiteSpace: 'nowrap', marginRight: '5px' }} 
                  disabled={currentVehicle?.gpsUrl === undefined} 
                  onClick={e => zoomToEntireRoute()}
                >
                  Rotayı Göster
                </Button>
                <Button 
                  size="sm"
                  style={{ fontSize: '12px', whiteSpace: 'nowrap' }} 
                  onClick={handleEditStops}
                  disabled={true}
                >
                  Durakları Düzenle
                </Button>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
      <style jsx>{`
        @keyframes copyAnimation {
          0% { transform: scale(1); }
          50% { transform: scale(1.2); }
          100% { transform: scale(1); }
        }

        .animate-copy {
          animation: copyAnimation 0.5s ease;
        }

        @media (min-width: 768px) {
          #open-sidebar-button {
            display: none;
          }
          #left-pane {
            display: block;
            width: 300px;
          }
        }
        @media (max-width: 768px) {
          #open-sidebar-button {
            display: block;
          }
          #left-pane {
            display: none;
          }
          .map-legend {
            bottom: 6vh;
          }
        }
        .marker {
          cursor: move;
        }
      `}</style>
      <Modal show={showStopModal} onHide={() => setShowStopModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Stop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control 
                type="text" 
                value={currentStop?.name || ''} 
                onChange={(e) => setCurrentStop({...currentStop, name: e.target.value})}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Order</Form.Label>
              <Form.Control 
                type="number" 
                value={currentStop?.order || ''} 
                onChange={(e) => setCurrentStop({...currentStop, order: parseInt(e.target.value)})}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Note</Form.Label>
              <Form.Control 
                as="textarea" 
                value={currentStop?.note || ''} 
                onChange={(e) => setCurrentStop({...currentStop, note: e.target.value})}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check 
                type="checkbox" 
                label="Is Passive" 
                checked={currentStop?.isPassive || false} 
                onChange={(e) => setCurrentStop({...currentStop, isPassive: e.target.checked})}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStopModal(false)}>
            Kapat
          </Button>
          <Button variant="primary" onClick={() => handleSaveStop(currentStop)}>
            Kaydet
          </Button>
          <Button variant="danger" onClick={() => handleDeleteStop(currentStop)}>
            Durağı Sil
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerVehicleDetail;